<template>
  <section style="background-color: none;width: 100%;" class="d-flex game-card pt-1 pr-1">
    <a class="custom-link">
      <span style="padding: 10px;margin-top: -20px;font-weight: bold;color: black;" @click="removePick(bet.match_id)">
        x
      </span>
    </a>
    <div class="content-section">

      <div class="row m-0 p-0">
        <div class="col-9 m-0 p-0">
          <div class="teams text-dark">
            {{ bet.competitor_1 }} v {{ bet.competitor_2 }} <span v-if="bet.live" class="text-danger">Live</span>
          </div>

        </div>

        <div class="col-3 m-0 p-0 text-right text-dark">
          <span style="color: black;font-weight: 700;" class="odds-bts">{{ bet.odd | formatOdds }}</span>
        </div>
      </div>

      <div class="row m-0 p-0">

        <div class="col-12 m-0 p-0">
          <div style="font-weight: 700;" class="small-text text-dark op-low">
            {{ bet.market_name }}
          </div>
        </div>

        <div class="col-12 m-0 p-0 pick text-dark small-text">
          <div style="font-weight: 700;" class="pick text-dark small-text p-0 op-low">
            Pick: <span class="text-dark">{{ bet.outcome_name }}</span>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Pick",
  props: {
    bet: {
      required: true
    }
  },
  methods: {
    init: function () {

      var vm = this;
      vm.previousOdds = this.bet.odd;

      //vm.id = this.getOddID(this.bet.match_id,this.bet.market_id,this.bet.outcome_id,this.bet.specifier,'odd');
      var topic = 'odds:' + vm.bet.odd_id;
      console.log('sub to ' + topic)
      this.EventBus.$on(topic, function (payload) {

        var hasChanged = false;

        if (parseFloat(payload.odd) !== parseFloat(vm.bet.odd)) {

          hasChanged = true;
        }

        if (parseInt(vm.bet.active) !== parseInt(payload.active)) {

          hasChanged = true;
        }

        if (parseInt(vm.bet.status) !== parseInt(payload.status)) {

          hasChanged = true;
        }

        if (hasChanged) {

          console.log(JSON.stringify(payload, undefined, 4));

          vm.bet.status = payload.status;
          vm.bet.active = payload.active;

          var oddsChangedPayload = {};

          oddsChangedPayload.id = vm.id;
          oddsChangedPayload.odd_id = vm.odd_id;
          oddsChangedPayload.odds = payload.odd;
          oddsChangedPayload.status = payload.status;
          oddsChangedPayload.active = payload.active;
          oddsChangedPayload.previous_odds = payload.odd;

          console.log('current ' + payload.odd + ' previous ' + vm.bet.previous_odds);

          if (parseFloat(payload.odd) !== parseFloat(vm.bet.odd)) {

            vm.previousOdds = vm.bet.previous_odds;
            vm.bet.odd = payload.odd;

            // odds changed
            if (parseFloat(payload.odd) > parseFloat(vm.previousOdds)) {

              var direction = 1

            } else {

              direction = 0

            }

            console.log('direction ' + direction);

            var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
            directionTxt = directionTxt + ' from ' + vm.formatOdds(vm.previousOdds) + ' to ' + vm.bet.odd;
            vm.bet.odds_change_text = directionTxt;
            oddsChangedPayload.previous_odds = vm.previousOdds;
            console.log('directionTxt ' + directionTxt);

          }

          vm.EventBus.$emit('odds:changed', oddsChangedPayload);

        }

      });

      this.EventBus.$on('producer:status', function (payload) {

        //var producer_id = payload.producer_id;
        vm.bet.producer_status = payload.producer_status;

        //if(parseInt(producer_id) === 1 ) {

        //vm.bet.producer_status = producer_status;

        // }

      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }


      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  mounted: function () {

    this.init();

  },
  data: function () {

    return {
      id: '',
      statusClass: '',
      previousOdds: '',
      oddActive: '',
      producerStatus: '',
      locked: false,
    }
  },
  watch: {
    bet: function () {
      //this.init();
    }
  },
  computed: {

    directionText: function () {

      return this.odds_change_text;

    },
    hasDirectionText: function () {

      return this.odds_change_text !== undefined && this.odds_change_text !== null && this.odds_change_text.length > 0;

    },

  }
}
</script>